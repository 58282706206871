@import '../../style/themes/index';
@import '../../style/mixins/index';

@comment-prefix-cls: ~'@{ant-prefix}-comment';

.@{comment-prefix-cls} {
  position: relative;

  &-inner {
    display: flex;
    padding: @comment-padding-base;
  }

  &-avatar {
    position: relative;
    flex-shrink: 0;
    margin-right: 12px;
    cursor: pointer;
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }

  &-content {
    position: relative;
    flex: 1 1 auto;
    min-width: 1px;
    font-size: 14px;
    word-wrap: break-word;

    &-author {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 4px;
      font-size: 14px;
      & > a,
      & > span {
        height: 18px;
        padding-right: 8px;
        font-size: 12px;
        line-height: 18px;
      }

      &-name {
        color: @comment-author-name-color;
        font-size: 14px;
        transition: color 0.3s;
        > * {
          color: @comment-author-name-color;
          &:hover {
            color: @comment-author-name-color;
          }
        }
      }

      &-time {
        color: @comment-author-time-color;
        white-space: nowrap;
        cursor: auto;
      }
    }

    &-detail p {
      white-space: pre-wrap;
    }
  }

  &-actions {
    margin-top: 12px;
    padding-left: 0;
    > li {
      display: inline-block;
      color: @comment-action-color;
      > span {
        padding-right: 10px;
        color: @comment-action-color;
        font-size: 12px;
        cursor: pointer;
        transition: color 0.3s;
        user-select: none;
        &:hover {
          color: @comment-action-hover-color;
        }
      }
    }
  }

  &-nested {
    margin-left: @comment-nest-indent;
  }
}
